<template>
    <div>
        <b-container class="pt-5 pb-5">
            <b-card no-body>
                <b-card-body>
                    <h1 class="fs-4 font-weight-bold mb-3">Komende evenementen</h1>
                    <template v-if="tickets.length > 0">
                        <b-card v-for="(ticket, key) in tickets" :key="key" class="mb-3">
                            <h4 class="fs-5 text-primary mb-0">{{ ticket.shop.company_name }}</h4>
                            <b-row>
                                <b-col sm="6" md="4">
                                    <div>{{ ticket.event.name }}</div>
                                    <div class="text-muted">
                                        {{ ticket.date.start_at|formatTextDateTime }} - {{ ticket.date.end_at|formatTextDateTime }}
                                    </div>
                                </b-col>
                                <b-col sm="6" md="4">
                                    <div>{{ ticket.firstname }} {{ ticket.lastname }}</div>
                                    <div class="text-muted">{{ ticket.email }}</div>
                                </b-col>
                                <b-col sm="12" md="4" class="text-right">
                                    <div class="mb-1">Prijs: {{ ticket.total_price|toCurrency }}</div>
                                    <b-button variant="primary" size="sm" @click="downloadTicket(ticket)">
                                        Ticket downloaden
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                    <template v-else>
                        <b-alert variant="secondary" show>
                            Er zijn geen tickets gevonden.
                        </b-alert>
                    </template>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    export default ({
        components: {},
        metaInfo: {
            title: 'Mijn Tickets',
        },
        data() {
            return {
                tickets: [],
            }
        },
        methods: {
            getTickets: function() {
                this.$axios.get("https://api.tixgo.nl/customer/tickets")
                    .then( response => {
                        this.tickets = response.data;
                    }
                );
            },
            downloadTicket(ticket) {
                this.$axios.get("https://api.tixgo.nl/customer/ticket/" + ticket.id)
                    .then( response => {
                        if (response.data.status == 'success') {
                            window.open(response.data.download_url, '_blank');
                        }
                    }
                );
            },
        },
        created() {
            this.getTickets();
        }
    });
</script>